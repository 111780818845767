const PopUp = ({ isOpen, onClose, message }) => {

    if (!isOpen) return null;

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1200,
        }} >
            <div style={{
                backgroundColor: 'rgb(204, 204, 204)',
                padding: '20px',
                borderRadius: '8px',
                textAlign: 'center',
                maxWidth: '400px',
                width: '100%',
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                zIndex: 1101,
                overflow: 'hidden',
            }}>
                <p style={{
                    color: 'black',
                    fontWeight: 'bold',
                }}>
                    {message}
                </p>
                <button
                    style={{
                        zIndex:1101,
                        backgroundColor: '#DC4B34',
                        border: 'none',
                        padding: '10px 20px',
                        color: 'white',
                        cursor: 'pointer',
                        borderRadius: '5px',
                        marginTop: '20px',
                    }}
                    onClick={onClose}>
                    OK
                </button>
            </div>
        </div>
    )
}

export default PopUp;


