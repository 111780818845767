import React, { useState, useEffect } from 'react';
import { archivePaymentRecord, archiveRetentionRecord, fetchActionData, fetchChatDataRetention, fetchOffersData, fetchOffersDataRetention, sendChatMessageRetention, sendOffer, sendOfferRetention } from '../../services/data';
import { useMutation } from '@tanstack/react-query';
import PopUp from '../PopUp';

export const BasicModalPayment = ({ isOpen, onClose, rowData }) => {
    const [data, setData] = useState(rowData || {});

    useEffect(() => {
        setData(rowData || {});
    }, [rowData]);



    const [popupOpen, setPopupOpen] = useState(false);
    const [popupMessage, setPopupMessage] = useState('')

    const archMutation = useMutation({
        mutationFn: archivePaymentRecord,
        onSuccess: (data) => {
            console.log('Record updated successfully:', data);
            onClose();
            window.location.reload();
        },
        onError: (error) => {
            console.error('Failed to send record:', error);
            setPopupOpen(true)
            setPopupMessage("Error archiving record.")
        }
    });

    const handleArchClick = () => {
        archMutation.mutate(data)
    }

    const handleClosePopup = () => {
        setPopupOpen(false);
        setPopupMessage('')
        window.location.reload()
    };

    if (!isOpen) return null;

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
            }}
        >
            <PopUp
                onClose={handleClosePopup}
                isOpen={popupOpen}
                message={popupMessage}
            />
            <div
                style={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    padding: '20px',
                    width: '50%',
                    maxHeight: '80%',
                    overflowY: 'auto',
                    position: 'relative',
                    color: 'black'
                }}
            >
                <button
                    style={{
                        position: 'absolute',
                        top: '0px',
                        right: '0px',
                        border: 'none',
                        background: 'transparent',
                        fontSize: '24px',
                        cursor: 'pointer',
                    }}
                    onClick={onClose}
                >
                    ×
                </button>
                <h2>Details</h2>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >

                    <div>
                        {Object.entries(data).length === 0 ? (
                            null
                        ) : (
                            Object.entries(data).map(([key, value]) => (
                                <div
                                    key={key}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: '8px',
                                        fontFamily: 'Arial, sans-serif'
                                    }}
                                >
                                    <label
                                        style={{
                                            fontWeight: 'bold',
                                            width: '150px',
                                            marginRight: '10px'
                                        }}
                                    >
                                        {key}:
                                    </label>
                                    {(value)}
                                </div>
                            ))
                        )}
                    </div>
                    <div style={{ display: 'flex', direction: 'row' }}>
                        <div style={{ flex: 3 }}>

                        </div>
                        <button
                            style={{
                                flex: '1',
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '4px',
                                backgroundColor: '#007bff',
                                color: 'white',
                                fontSize: '16px',
                                cursor: 'pointer',
                                marginRight: '10px'
                            }}
                            onClick={handleArchClick}
                        >
                            Archieve
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const BasicModalRetention = ({ isOpen, onClose, rowData }) => {
    const [data, setData] = useState(rowData || {});

    useEffect(() => {
        setData(rowData || {});
    }, [rowData]);

    const handleInputChange = (key, newValue) => {
        setData(prevData => ({
            ...prevData,
            [key]: newValue
        }));
    };


    const [showChat, setShowChat] = useState(false);

    const [messages, setMessages] = useState([]);

    const [inputValue, setInputValue] = useState('');


    const loadChatData = async () => {
        try {
            const result = await fetchChatDataRetention(data.id);
            console.log(result.data)
            setMessages(result.data.data)
        } catch (error) {
            alert(error.message);
        }
    };

    const loadActionData = async () => {
        try {
            const result = await fetchActionData(data.userId);
            setActions(result?.data?.data?.data)
        } catch (error) {
            alert(error.message);
        }
    };

    const [actions, setActions] = useState([
        {}
    ]);

    const sendMessage = async (e) => {
        e.preventDefault();
        if (inputValue.trim()) {
            if (inputValue.length < 3) {
                alert('Input must be at least 3 characters long');
            } else {
                try {
                    await sendChatMessageRetention(data.id, inputValue)
                } catch (error) {
                    alert(error)
                }
                await loadChatData();
                setInputValue('');
            }
        }
    };


    const offerMutation = useMutation({
        mutationFn: sendOfferRetention,
        onSuccess: (data) => {
            console.log('Record updated successfully:', data);
            onClose();
            window.location.reload();
        },
        onError: (error) => {
            console.error('Failed to send record:', error);
            alert("Error updating")
        }
    });

    const loadOffersData = async (gateway) => {
        if (gateway == "STRIPE" || gateway == "RAZORPAY") {
            try {
                const result = await fetchOffersDataRetention(gateway);
                setOffers(result?.data?.data?.data)
            } catch (error) {
                alert(error.message);
            }
        }
    };

    const handleOffer = () => {
        offerMutation.mutate({ retentionId: data.id, offerId: selectedOffer })
    }

    const [offers, setOffers] = useState([]);
    const [enableOffer, setEnableOffer] = useState(true)
    const [selectedOffer, setSelectedOffer] = useState('');

    const handleOfferChange = (event) => {
        setEnableOffer(false)
        setSelectedOffer(event.target.value);
    };

    useEffect(() => {
        if (data.id) {
            loadOffersData(data.gateway)
            loadChatData();
            loadActionData();
        }
    }, [data]);

    const [popupOpen, setPopupOpen] = useState(false);
    const [popupMessage, setPopupMessage] = useState('')

    const archMutation = useMutation({
        mutationFn: archiveRetentionRecord,
        onSuccess: (data) => {
            console.log('Record updated successfully:', data);
            onClose();
            window.location.reload();
        },
        onError: (error) => {
            console.error('Failed to send record:', error);
            setPopupOpen(true)
            setPopupMessage("Error archiving record.")
        }
    });

    const handleArchClick = () => {
        archMutation.mutate(data)
    }

    const handleClosePopup = () => {
        setPopupOpen(false);
        setPopupMessage('')
        window.location.reload()
    };

    if (!isOpen) return null;

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
            }}
        >
            <PopUp
                onClose={handleClosePopup}
                isOpen={popupOpen}
                message={popupMessage}
            />
            <div
                style={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    padding: '20px',
                    width: '50%',
                    maxHeight: '80%',
                    overflowY: 'auto',
                    position: 'relative',
                    color: 'black',
                }}
            >
                <button
                    style={{
                        position: 'absolute',
                        top: '0px',
                        right: '0px',
                        border: 'none',
                        background: 'transparent',
                        fontSize: '24px',
                        cursor: 'pointer',
                    }}
                    onClick={onClose}
                >
                    ×
                </button>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            gap: '10px',
                        }}
                    >
                        <div style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            height: '100%',
                            width: '100%',
                        }}>

                            <div style={{ flex: 1 }}>


                                <h2>Details</h2>
                                <div>
                                    {Object.entries(data).length === 0 ? (
                                        null
                                    ) : (
                                        Object.entries(data).map(([key, value]) => (
                                            <div
                                                key={key}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginBottom: '8px',
                                                    fontFamily: 'Arial, sans-serif'
                                                }}
                                            >
                                                {key != 'status' ?
                                                    <div>
                                                        <label
                                                            style={{
                                                                fontWeight: 'bold',
                                                                width: '150px',
                                                                marginRight: '10px'
                                                            }}
                                                        >
                                                            {key}:
                                                        </label>
                                                        {(value)}
                                                    </div>
                                                    : <div>
                                                        <label
                                                            style={{
                                                                fontWeight: 'bold',
                                                                width: '150px',
                                                                marginRight: '10px'
                                                            }}
                                                        >
                                                            Status
                                                        </label>
                                                        <select id="options" value={data.status}
                                                            onChange={(e) => handleInputChange("status", e.target.value)}
                                                            style={{
                                                                padding: "5px",
                                                                fontSize: "16px",
                                                                borderRadius: "5px",
                                                                border: "1px solid #ccc",
                                                                backgroundColor: "#f9f9f9",
                                                                color: "black",
                                                                outline: "none",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <option value="CREATED">CREATED</option>
                                                            <option value="RECOVERED">RECOVERED</option>
                                                            <option value="LOST">LOST</option>
                                                            <option value="AUTO_RECOVERED">AUTO_RECOVERED</option>
                                                            <option value="IN_PROGRESS">IN_PROGRESS</option>

                                                        </select>
                                                    </div>
                                                }
                                            </div>
                                        ))
                                    )}
                                    <div>
                                        <label>Choose an offer:</label>
                                        <select value={selectedOffer} onChange={handleOfferChange}
                                            style={{
                                                padding: "5px",
                                                fontSize: "16px",
                                                borderRadius: "5px",
                                                border: "1px solid #ccc",
                                                backgroundColor: "#f9f9f9",
                                                color: "black",
                                                outline: "none",
                                                cursor: "pointer",
                                                marginRight: '10px'
                                            }}
                                        >
                                            <option value="">--Please choose an option--</option>
                                            {offers.filter((offer) => offer.planId === data.planId)
                                                .map((offer) => (
                                                    <option key={offer.id} value={offer.id}>
                                                        {offer.name}
                                                    </option>
                                                ))}
                                        </select>
                                        <button onClick={handleOffer} disabled={enableOffer} style={{
                                            padding: '5px',
                                            border: '1px solid #ccc',
                                            borderRadius: '8px',
                                            backgroundColor: '#43C185',
                                            color: '#fff',
                                            cursor: 'pointer',
                                        }}>
                                            Give
                                        </button>
                                    </div>
                                </div>


                                <div style={{ height: '10px;' }}>
                                    {showChat ? <a style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { setShowChat(false) }}>hide chat</a> :
                                        <a style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { setShowChat(true) }}>show chat</a>}
                                </div>

                            </div>
                        </div>
                        <div
                            style={{
                                flex: 1,
                                marginTop: '10px',
                                marginRight: '10px',
                                padding: '20px',
                                backgroundColor: '#f0f0f0',
                                border: '1px solid #ccc',
                                textAlign: 'center',
                            }}
                        >
                            Action history

                            <div
                                style={{
                                    width: '100%',
                                    maxHeight: '400px',
                                    overflowY: 'scroll',
                                    border: '1px solid #ccc',
                                    padding: '10px',
                                    backgroundColor: '#f9f9f9',
                                    textAlign: 'start'
                                }}
                            >
                                {actions.length > 0 ? (
                                    actions.map((action, index) => (
                                        <div key={index} style={{ marginBottom: '10px', padding: '10px', backgroundColor: 'white', borderRadius: '4px' }}>
                                            {action.actionType}
                                        </div>
                                    ))
                                ) : (
                                    <p>No Actions to display</p>
                                )}
                            </div>

                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '10px' }}>
                    <div style={{ flex: 3 }}>

                    </div>
                    <button
                        style={{
                            flex: '1',
                            padding: '10px 20px',
                            border: 'none',
                            borderRadius: '4px',
                            backgroundColor: '#007bff',
                            color: 'white',
                            fontSize: '16px',
                            cursor: 'pointer',
                            marginRight: '10px'
                        }}
                        onClick={handleArchClick}

                    >
                        Archieve
                    </button>

                </div>
            </div>
            <div
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    width: '400px',
                    color: 'black',
                    zIndex: 999,
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                }}
            >
                {showChat ?
                    <div style={{ flex: 1, color: 'black', zIndex: 1000 }}>
                        <div style={{
                            width: '400px',
                            height: '500px',
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '10px',
                            zIndex: 1000,
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                        }}>
                            <button
                                style={{
                                    position: 'absolute',
                                    top: '0px',
                                    right: '0px',
                                    border: 'none',
                                    background: 'transparent',
                                    fontSize: '24px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => { setShowChat(false) }}
                            >
                                ×
                            </button>
                            <div style={{
                                flex: 1,
                                overflowY: 'auto',
                                marginBottom: '5px',
                            }}>
                                {messages.map((message, index) => (
                                    <div key={index} style={{ marginBottom: '1px' }}>
                                        {message.admin.name}: {message.message}
                                        <hr />
                                    </div>
                                ))}
                            </div>
                            <form onSubmit={sendMessage} style={{
                                display: 'flex',
                            }}>
                                <input
                                    type="text"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    placeholder='message'
                                    style={{
                                        flex: 1,
                                        padding: '10px',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        marginRight: '10px',
                                    }}
                                />
                                <button type="submit" style={{
                                    padding: '8px',
                                    border: '1px solid #ccc',
                                    borderRadius: '8px',
                                    backgroundColor: '#43C685',
                                    color: '#fff',
                                    cursor: 'pointer',
                                }}>
                                    Send ➤
                                </button>
                            </form>
                        </div>
                    </div>
                    : null}            </div>
        </div>
    );
};


